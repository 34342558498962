import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/common/Axios'
import axios1 from 'axios'
import 'amfe-flexible'
import { i18n } from './i18n/index'
import Vant from 'vant';
import store from '@/store'
import 'vant/lib/index.css';
import "@/assets/css/style.css";
import Loading from './components/loadingPlugin.js'; // 引入Loading组件

import echarts from 'echarts'
import Model from '@/common/Model'
import Dialog from '@/common/Dialog'
import Util from '@/common/Util'
import moment from 'moment'
import VueResource from "vue-resource";
import Clipboard from "v-clipboard";
let $bus = new Vue()

Vue.use(Loading); // 使用Loading插件
Vue.use(Vant);
Vue.use(Clipboard);
Vue.prototype.$echarts = echarts
Vue.prototype.$Moment = moment;
Vue.prototype.$Model = Model
Vue.prototype.$Dialog = Dialog
Vue.prototype.$Util = Util
Vue.prototype.$bus = $bus

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next();
// })

router.afterEach(() => {

})


Vue.prototype.$http = axios
Vue.prototype.$http1 = axios1

// 添加请求拦截器
axios.interceptors.request.use(function (config) {

  return config
}, function (error) {
  return Promise.reject(error);
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {

  return response;
})



// 全局点击防抖处理
const on = Vue.prototype.$on
Vue.prototype.$on = function (e, func) {
  // 目前只有点击需要防抖
  if (e === 'click' || e === 'on-click') {
    let sto
    on.call(this, e, function () {
      sto ? clearTimeout(sto) : func.apply(this, arguments)
      sto = setTimeout(() => sto = undefined, 500)
      // 点击后移除焦点
      this.$el && this.$el.blur()
    })
  } else {
    on.call(this, e, func)
  }
}


Vue.config.productionTip = false

// Model.GetBackData()
/*路由登录逻辑*/
router.beforeEach((to, from, next) => {
  if (from.name != 'login' && from.name != 'reg' && from.name != 'repwd') {
    localStorage['FromPage'] = from.fullPath;
  } else {
    localStorage.removeItem('FromPage');
  }
  let token = localStorage['Token'];
  let mm = to.matched.some(record => record.meta.requiresAuth)
  if (to.name != 'login' && to.name != 'reg' && to.name != 'repwd' && !token) {
    next('/login');
  } else {
    next();
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
