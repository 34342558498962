const tab = {
  text: [
    '首頁',
    '足球',
    '註單',
    '投注記錄'
  ]
}
const dialog = [
  "提示",
  "確定",
  "正在提交...",
  "複製成功",
  "IOS系統版本低不支持",
  "正在註冊...",
  "數據加載中...",
  "沒有更多了"
]

const index = {
  title: '早盤',
  title2: '滾球',
  zuqiu: '足球',
  Alltime: '所有時間',
  timeAndPlay: '時間/聯賽'
}
const login = {
  accont: '登入帳號',
  phone: '手機號',
  mail: '郵箱',
  pwd: '密碼',
  placeholder1: '請輸入手機號',//'
  placeholder_m: '請輸入郵箱',//'
  placeholder2: '請輸入帳號',//'Please enter your accont',
  placeholder3: '請輸入密碼',//'Please enter a password',
  placeholder4: '請輸入驗證碼',//'Please enter a password',
  placeholder5: '請輸入邀請碼',//Please enter the recommendation code
  forgotPwd: '忘記密碼？',
  forgotPwd1: '忘記密碼',
  Reset_password: '重置密碼',
  login: '登入',
  Dont_have_an_account_yet: '還沒帳號？',
  yklogin: '遊客登入',
  zxkf: '網路客服',
  remember: '記住我的帳號',
  Register_now: '立即註冊',
  Login_now: '前往登入',
  Register: '註冊',
  VerificationCode: '驗證碼',
  ConfirmPassword: '確認密碼',
  getCode: '驗證碼',
  send: '已發送',
  Referral_Code: '邀請碼',
  by: '點擊註冊表示我已閱讀並同意',
  User_Agreement: '用戶協議',
  submit: '確認',

  read1: '我已閱讀並同意',
  read2: '相關條款',
  read3: '和',
  read4: '隱私政策',
}

const lang = {
  text: '語言',
}

const recharge = {
  rechargeLog: '充值記錄',
  title: 'USDT充值頁面',
  money: '金額',
  btn1: '掃描二維碼充值後提交',
  recharge: '充值',
  ERC20: 'ERC20',
  TRC20: 'TRC20',
  OMNI: 'OMNI',
  text: [
    '此地址僅能存入USDT，其他幣種存款無效且不予退款',
    'TKQeybLDtotxGMxaEpROWgESjTtvGqH98L'
  ],
  copy: '複製',
  label: [
    "類別",
    "地址",
    "充值金額",
    "輸入金額",
    "上傳憑證",
    "複製成功",
    "交易所"
  ],
  default: [
    "錢包充值",
    "Wallet pre-recharge",
    "Recharge method",
    "Recharge",
    "confirm",
  ],
  coinRecharge: [
    '虛擬幣充值', '幣種', '錢包地址', "地址二維碼"
  ],

  balanceNotEnough: '餘額不足',
  balanceTip: "請去充值"
}
const withdraw = {
  withdrawLog: '提現記錄',
  withdraw: '提現',
  AccountBalance: '賬戶餘額',
  FreezeBalance: '凍結餘額',
  WithdrawalAddress: '提款地址',
  ChainName: '鏈名稱',
  WithdrawalAmount: '提款金額',
  TransactionPassword: '交易密碼',
  p1: '請輸入提款地址',
  p2: '請輸入提款金額',
  p3: '請輸入交易密碼',
}

const my = {
  text: [
    '帳戶歷史',
    '額度修改記錄',
    '語言',
    '盘口數據',
    '修改密碼',
    '簡易密碼登入',
    '密碼恢復',
    '常見問題',
    '賠率轉換',
    '系統要求',
    '規則與條款',
    '聯繫我們',
  ],
  text1: [
    '激活'
  ],
  setting: '設定',
  help: '幫助',
  logout: '登出'
}

const zuqiu = {
  text: [
    '時間',
    '聯賽',
    '賽事篩選',
    '讓球',
    '大小','半場','所有盘口'
  ],
  hands:{
    'mh':'香港盤',
    'wb':'香港盤2'
  },
  wanfa: [
    '滾球',
    '早盤',
  ],
  qiu: [
    '足球',
    '籃球',
    '電競足球',
    '網球',
    '羽毛球',
  ],
  type: [
    '主要玩法',
    '讓球',
    '进球',
    '半場',
    '间隔',
  ],
  text1: [
    '讓球',
    '得分大小',
    '獨贏',
  ],
  big: '大',
  small: '小',
  text2: [
    '主',
    '客',
    '和',
  ],
  zhudan: '註單',
  xiazhu: '下注',
  month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  month_s: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',],
}
const order = {
  type: [
    '交易狀況',
    '數據統計',
  ],
  allTy: '所有體育',
  money1: '投注金額',
  money2: '可贏金額',
  all: '總共',
  removeAll: '確定移除所有投注?',
  table_title: [
    '日期',
    '投注金額',
    '有效金額',
    '當前餘額',
  ],
  tzlog:["聯賽","比賽","全場比分","訂單號","投注金額","投注項","投注內容","投注類型","賠率","中獎金額",
    "狀態","下注時間"],
}

const changePwd = {
  text: [
    '為了安全起見，您必須每30天改變一次密碼。',
    '您的密碼必須根據以下規則:',
    '1、至少要有兩個大或小寫英文字母和數字(0 - 9)組合, 字數最少6至12個。',
    '2、三個不同的字母數字。',
    '3、不准許有空格。',
  ],
  changePwd: '修改密碼',
  now_pwd: '當前密碼',
  new_pwd: '新密碼',
  sure_pwd: '確認密碼',
  btn1: '取消',
  btn2: '提交',
}

const simplePwd = {
  simplePwd: '簡易密碼登入',
  text: [
    '輸入您的簡易密碼',
    '設定您的簡易密碼以便快速登入。',
    '關閉',
    '刪除',
  ]
}

const resetPwd = {
  'resetPwd': '密碼恢復',
  'text': [
    '請輸入您用於恢復密碼的電子信箱地址。',
    '電子郵箱地址',
    '取消',
    '下一步',
  ]
}

const question = {
  title:'新手幫助',
  question: '常見問題',
  time: '最後更新日期:'
}

const odds = {
  odds: '賠率轉換',
  table_title1: [
    '盘口類型',
    '投注金額',
    '賠率',
    '贏',
    '輸',
  ],
  title: '賠率轉換表格',
  table_title2: [
    '香港盤',
    '馬來盤',
    '印尼盤',
  ]
}
const system = {
  system: '系統需求',
  text1: [
    '手機操作系統',
    '操作系統',
    '需求',
    '安卓',
    '安卓7或以上',
    'IOS',
    'IOS12或以上',
  ],
  text2: [
    '手機瀏覽器',
    '安卓瀏覽器',
    '需求',
    'chrome',
    '版本87或以上',
    'QQ瀏覽器',
    '版本10或以上',
  ],
  text3: [
    'IOS瀏覽器',
    'IOS瀏覽器',
    '需求',
    'safari',
    '版本11或以上',
    'chrome',
    '版本84或以上',
  ],
  text4: [
    '桌面操作系統',
    '操作系統',
    '需求',
    'windows',
    'windows 7或是10',
    'Mac OS',
    'OS X10.10或以上',
  ],
  text5: [
    '桌面瀏覽器',
    'windows瀏覽器',
    '需求',
    'chrome',
    '版本85或以上',
    '火狐',
    '版本60或以上',
  ],
  text6: [
    '不支援的瀏覽器和操作系統',
    '為了提供最佳的用戶體驗，我們將保持所支援的瀏覽器或操作系統的清單簡短。如果有漏洞或錯誤出現在那些我們所沒有支援的瀏覽器或操作系統裡面，我們保留不約予修正的權利。倘若您還是堅持使用那些不被我們所支援的瀏覽器或操作系統，您可能會遇到系統的效能問題或關於畫面上的問題。'
  ]
}

const type = {
  type: [
    '全部',
    '進行中',
    '失敗',
    '成功',
  ]
}

const rule = {
  rule: '規則與條款',
  text1: [
    '使用條款',
    '1.享用本公司提供的服務是客戶本人的意願，其風險應由戶本人承擔。參與我們服務的同時就說明客戶認同本公司所提供的服務是正常、合理、公正、公平的。<br><br>2.某些法律規則在貴國居住的司法管轄區; 並未明文規定在線和非在線博彩是否允許，客戶有責任確保任何時候您的博彩行為在您所屬地是屬合法行為。'
  ],
  text2: [
    '投注接受條件',
    '1.享用本公司提供的服務是客戶本人的意願，其風險應由戶本人承擔。參與我們服務的同時就說明客戶認同本公司所提供的服務是正常、合理、公正、公平的。<br><br>2.某些法律規則在貴國居住的司法管轄區; 並未明文規定在線和非在線博彩是否允許，客戶有責任確保任何時候您的博彩行為在您所屬地是屬合法行為。'
  ],
  text3: [
    '免責條款',
    '1.享用本公司提供的服務是客戶本人的意願，其風險應由戶本人承擔。參與我們服務的同時就說明客戶認同本公司所提供的服務是正常、合理、公正、公平的。<br><br>2.某些法律規則在貴國居住的司法管轄區; 並未明文規定在線和非在線博彩是否允許，客戶有責任確保任何時候您的博彩行為在您所屬地是屬合法行為。'
  ],
}

const contactUs = {
  contactUs: '聯繫我們'
}
const tzLog = {
  type: [
    '未結算',
    '已結算',
    '預約投注',
  ]
}

const detail = {
  text: [
    '暫無直播','全場','半場'
  ],
  type: [
    '全部',
    '熱門',
    '讓球&大/小',
    '波膽',
    '進球',
    '特殊',
  ]
}

const home = {
  tabTitles: [
    '體育競猜',
    '哈希遊戲',
    '電子遊藝',
    '真人視訊'
  ],
  contentTitles: [
    ['滾球', '早盤'],
    ['幸運哈希', '哈希牛牛', '哈希莊閒', '幸運數字', '幸運28'],
    ['捕魚', '輪盤', '大小'],
    ['百家樂', '21點', '龍虎']
  ],
  contentSubtitles: [
    ['邊看比賽邊下注', '賠率提前知,投注更準確'],
    ['幸運哈希', '哈希牛牛', '哈希莊閒', '幸運數字', '幸運28'],
    ['捕魚', '輪盤', '大小'],
    ['百家樂', '21點', '龍虎']
  ],
  titles: [
    '熱門遊戲',
    '賠率',
    '立即投注'
  ],
  guides: [
    '新手指南',
    '充值提現',
    '常見問題'
  ],
  guideDescs: [
    '了解如何開始遊戲',
    '快速充值和提現',
    '解決常見問題'
  ],
  menu: [
    '首頁',
    '公告'
  ],
  msg: '敬請期待',
  newsTitle: '新聞資訊',
  moreNews: '更多資訊 >'
}

const hash = {
  common: ["網路客服", "取消懸浮","確認","取消","請輸入"],
  vanPull: ["沒有更多數據了", "沒有數據"],
  dialog: [
    "提示",
    "確定",
    "正在提交...",
    "複製成功",
    "IOS系統版本低不支持",
    "正在註冊...",
    "數據加載中...",
  ],
  roomDescs:['初級','中級','高級','至尊'],
  rooms:["起投","進入房間餘額限制","目前房間用戶數","進入房間"],
  kaijiang:["開獎",'查看詳情','前一天','後一天'],
  taskshow :[
    '期數','更多開獎','玩法介紹','投注截至','開獎紀錄','投注金額','或輸入下注金額',
    '賬戶餘額','立即下注','已停止','您的賬號余额不足，请先儲值',
    ['確定購買','本次下注','是否確認下注?','取消下注','確認下注','本次投注成功','查看訂單','返回投注'],
    ['獎金期已到期,停止下注'],
    ['當前期','開獎結果','下一期','剩余開獎時間']
  ],
  task: {
    tabs: ["全部","進行中", "已失效", "已完成","等待商家支付","等待確認"],
    default: ["訂單列表",],
    msg: ["提交審核", "已提交，請等待審核", "提交失敗，請重新提交"],
  },
  task3:["全部","未支付","已支付"],
  taskshow2:["祝賀","中獎","大小露珠",'單雙露珠','購買總額',"小時","分鐘","秒","進入遊戲","分",'等待開獎中'],
  taskshow3:["该游戏需要10倍投注额作为押金,"],
  mytask:["下注投注記録","彩種","帳號","時間","期數","金額","Hash串","中獎情況","中獎金額","訂單號","未中獎","中獎","餘額不足","價格不能為0","範圍錯誤"],
  mytask2:["未開獎","訂單詳情","需要先預扣除","開獎訊息","投注","和","部分中獎"],
  recharge2:['请按照以下给出信息完成儲值','金額不能為0','必須上傳截圖'],
  tips:['逐步开放中，敬请期待','敬请期待'],
  article:['系統訊息'],
  news:[
    "新聞資訊",
    ["焦點","行業","喜訊",],
  ],
}

export {
  detail,
  tzLog,
  dialog,
  withdraw,
  tab,
  index,
  login,
  lang,
  recharge,
  my,
  zuqiu,
  order,
  changePwd,
  simplePwd,
  resetPwd,
  question,
  odds,
  system,
  rule,
  contactUs,
  type,
  home,
  hash
}
