const tab = {
  home:'홈',
  find:'발견',
  position:'보유',
  collect:'즐겨찾기',
  my:'내 정보'
}

const dialog = [
  "프롬프트",
  "확인",
  "제출 중...",
  "복사 성공",
  "IOS 시스템 버전이 너무 낮아 지원되지 않습니다",
  "등록 중...",
  "데이터 로딩 중...",
  "더 이상 없습니다."
]

const index = {
  welcome:'환영합니다',
  TNkkrich:'TNkkrich',
  notice:'NFT 기술의 영향력 글로벌 디지털 자산 정책 NFT 뉴스 이벤트 NFT 거래 주문 유형 및 단계',
  PreSale: '사전 판매 중',
  TransactionList: '거래 목록',
  num:'수량',
  minPrice:'최저 회수 가격',
  nftDetail:'NFT 상세 정보',
  Countdown:'카운트다운',
  nftInfo:'NFT 정보',
  publisherInfo:'발행자 정보',
  features:'작품 특징',
  desc:'작품 설명',
  prePrice: '사전 판매 가격',
  RepurchasePrice:'회수 가격',
  PurchaseType:'구매 유형',
  oerderNow:'지금 주문',
  myCollect: '내 즐겨찾기',
  myCollect1: '내 조각',
  Synthesis: '합성',
  hold: '보유',
  Introduction:'소개',
  ViewTheAuthor:'작가 보기'
}

const find = {
  search:'원하는 내용을 검색하세요',
  myCollect: '내 즐겨찾기',
  myCollect_desc:'조각화된 상위 NFT 아트 전시회는 모두 TNFT에 있습니다',
  NFTsp: 'NFT 조각',
  NFTsp_desc: '보유한 NFT를 조각으로 나누세요',
  BecomeAmerchant: '발행자가 되세요',
  BecomeAmerchant_desc:'발행자가 되어 자신만의 NFT를 발행하세요',
  Announcement_headlines: '공지사항',
  About_NFT: 'NFT에 대해',
  Creation_Center: '창작 센터',
  Synthesis_center: '합성 센터',
  issure:'발행 기관'
}

const position = {
  Mypositions:'내 보유',
  PositionMarketValue: '보유 시장 가치(USDT)',
  TodayProfitAndLoss: '오늘의 손익',
  TotalProfitAndLoss: '총 손익',
  HoldingCost: '보유 비용',
  Number_of_fragments: '조각 수',
  PledgeShards: '조각 담보',
  PledgeIncome: '담보 수익',
  TNFTRoyaltiesTrade:'TNFT 로열티 거래',
  QuickBuy: '즉시 구매',
  QuickSell: '즉시 판매',
  History:'역사',
  name:'이름',
  price:'실시간 가격',
  Market:'시장 가치',
  Pledge: '담보',
  TNFT: 'TNFT',
  BuyingPrice: '구매 가격',
  SellingPrice: '판매 가격',
  EstimatedBuybackPrice:'예상 회수 가격',
  RepurchasePriceRatio:'회수 가격 비율',
  BuyQuantity:'구매 수량',
  SellQuantity:'판매 수량',
  placeholder1: '구매 수량을 입력하세요',
  placeholder2: '판매 수량을 입력하세요',
  submit:'제출',
  OTCwallet:'OTC 지갑',
  SellToOTCbalance:'OTC 잔액으로 판매',
  trxnumber:'TRX 수량',
  onSale:'청구서',
  sellingScope:'판매 범위',
  hangingOrderPrice:'선불 가격',
  surplus:'나머지',
  withdraw:'철회',
  completed:'완료됨',
  withdrawn:'철회됨',
  sellOut:'매도',
  purchase:'매입',
  comfirmTips:'취소 확인?',
  confirm:'확인',
  cancel:'취소',
  unlimited:'무제한'
}

const order ={
  orderHistory:'주문 내역',
  all: '전체',
  inProgress: '진행 중',
  expired: '만료됨',
  completed: '완료됨',
  orderID:'주문 번호',
  Payable:'지불해야 함',
  buy:'구매',
  viewNow:'지금 보기',
  PaymentDetails:'지불 세부 정보',
  PurchaseType:'구매 유형',
  OrderTime:'주문 시간',
  State:'상태'
}

const login = {
  TNFT_account_login:'TNFT 계정 로그인',
  phone:'휴대폰 번호',
  mail:'이메일',
  pwd:'비밀번호',
  placeholder1: '휴대폰 번호를 입력하세요',
  placeholder2: '이메일을 입력하세요',
  placeholder3: '비밀번호를 입력하세요',
  placeholder4: '비밀번호를 입력하세요',
  placeholder5: '초대 코드를 입력하세요',
  forgotPwd: '비밀번호를 잊으셨나요?',
  forgotPwd1: '비밀번호를 잊으셨나요',
  Reset_password :'비밀번호 재설정',
  login:'로그인',
  Dont_have_an_account_yet: '계정이 없으신가요?',
  Register_now: '지금 등록하세요',
  Register:'등록',
  VerificationCode:'인증 코드',
  ConfirmPassword:'비밀번호 확인',
  getCode:'인증 코드 받기',
  send:'전송됨',
  Referral_Code:'초대 코드',
  by:'등록을 클릭하면 읽고 동의한 것으로 간주됩니다',
  User_Agreement:'사용자 동의서',
  submit: '확인'
}

const share = {
  My_invitation_code:'내 초대 코드',
  desc:'TNFT를 재생하려면 스캔하세요, 거래 시 로열티 0, 더 많은 추천 로열티',
  save:'QR 코드 저장'
}

const my = {
  Merchant:'상인',
  OTCwallet:'OTC 지갑',
  myCollect: '내 즐겨찾기',
  myNFT:'내 NFT',
  Fragments: '조각',
  OTCbalance: 'OTC 잔액',
  Exchange:'로열티 교환',
  Transfer_to_NFT:'NFT로 전송',
  Staking_TNFT: 'TNFT 담보',
  Account_binding: '계정 연동',
  Invite_members: '회원 초대',
  Contact_Customer_Service: '고객 서비스에 연락',
  sign_out:'로그아웃',

  Apply_to_be_a_merchant:'상인 신청',
  Company_Name:'기관 이름',
  p_e_Company_Name:'기관 이름을 입력하세요',
  Country: '국가',
  p_e_Country: '국가를 입력하세요',
  Introduction: '소개',
  p_e_Introduction: '소개를 입력하세요',
  Business_license:'영업 허가증',
  Front_side_of_legal_person_certificate:'법인 증명서 앞면',
  The_reverse_side_of_the_legal_person_certificate:'법인 증명서 뒷면',

  Apply_to_be_a_auth:'실명인증 신청',
  auth_realname:'이름',
  auth_idcard:'주민등록번호',
  bank_name:'은행명',
  bank_account:'은행 계좌명',
  bank_card:'은행 계좌번호',
  Front_side_of_certificate:'신분증 정면',
  The_reverse_side_of_certificate:'신분증 뒷면',
  p_auth_realname: '이름을 입력하십시오.',
  p_auth_idcard: '주민등록번호를 입력하십시오.',
  p_bank_name:'은행 이름을 입력하십시오.',
  p_bank_account:'은행 계좌 이름을 입력하십시오.',
  p_bank_card:'은행 계좌를 입력하십시오.',

  Submit:'제출',
  reSubmit:'다시 제출',

  Edit_information: '정보 수정',
  avatar: '아바타',
  Nickname: '실명',
  Account: '계정',
  Referral_code: '추천 코드',
  Credit_score: '신용 점수',
  SecuritySettings: '보안 설정',
  Transaction_password: '거래 비밀번호',
  New_Transaction_password: '새 거래 비밀번호',
  Login_password: '로그인 비밀번호',
  New_Login_password: '새 로그인 비밀번호',
  ConfirmPassword: '비밀번호 확인',
  p_e_Nickname: '이름을 입력하세요',
  p_e_Password: '비밀번호를 입력하세요',

  Redeem_NFT: 'NFT 교환',
  Synthesis: '합성',
  price: '가격',
  Number_of_holdings:'보유 수량',
  Price_trend: '가격 추세',
  Buy: '구매',
  Sell: '판매',
  OTCtrading:'OTC 거래',
  OTC_quick_transaction:'OTC 빠른 거래',
  OTC_desc:'빠른 거래를 통해 현재 OTC 시장에서 최고의 가격으로 거래를 완료할 수 있습니다',
  I_want_to_pay: '지불하고 싶습니다',
  I_will_receive: '받을 예정입니다',
  Proposed_price: '제안 가격',
  How_to_use_OTC:'OTC 사용 방법',
  OTCdesc1: '1. 디지털 통화를 구매하기 전에 먼저 OTC 계정을 등록해야 합니다. 제공된 이메일 주소를 통해 계정을 인증하거나 휴대폰으로 받은 인증 코드를 사용하여 인증하거나 TNFT 계정을 사용하여 직접 등록하십시오.',
  OTCdesc2: '2. 거래의 안전성과 준수를 보장하기 위해 신원 확인을 위해 신분 증명서를 제공해야 합니다. 플랫폼은 제공된 신원 정보를 검토할 것입니다. 이 과정은 시간이 걸릴 수 있으므로 인내심을 가지십시오.',
  OTCdesc3: '3. 계정 등록이 완료되고 신원 확인이 통과되면 디지털 통화 구매를 시작할 수 있습니다. 구매 페이지에 들어가서 구매하려는 디지털 통화를 선택하십시오. 구매 수량을 입력하고 거래를 확인하십시오. 거래를 확인하기 전에 항상 주문의 모든 세부 정보를 확인하십시오. 주문이 일치되고 결제가 완료되면 디지털 통화가 OTC 계정으로 전송될 때까지 기다리십시오.',

  OTCrecords:'OTC 기록',
  Unit_price:'단가',
  Quantity:'수량',
  Approved:'승인됨',
  Cancelled:'취소됨',
  NFT_transfer_record:'NFT 전송 기록',

  NFT_transfer_in:'NFT 전송',
  Category:'카테고리',
  address: '주소',
  p_e_address: '주소를 입력하세요',
  NFTname:'NFT 이름',
  Features:'특징',
  Description:'설명',
  p_e_name: '이름을 입력하세요',
  p_e_Features: '특징을 입력하세요',
  p_e_Description: '설명을 입력하세요',
  Upload_NFT_file:'NFT 파일 업로드',
  Upload_transfer_certificate:'전송 증명서 업로드',
  copy_success:'복사 성공',
  Confirm:'확인',
  Pledge: '담보',
  Hold: '보유',
  Pre_sale_price:"사전 판매 가격",
  Time_day:'시간(일)',
  Pledge_quantity:'담보 수량',
  p_e_quantity:'수량을 입력하세요',
  Quantity_Available:'사용 가능한 수량',
  all:'전체',
  Fragment_reward_days:'조각 보상(일)',
  Remark:'비고',
  Pledge_time:'담보 시간',
  Interest_accrual_time: '이자 발생 시간',
  Redemption_time: '상환 시간',
  Award:'상',
  Creation_time:'생성 시간',
  End_time:'종료 시간',
  Unpledge:'담보 해제',
  Team_list:'팀 목록',
  account:'계정',
  zctime:'등록 시간',
  Royalty_Details:'로열티 세부 정보',
  income: '수입',
  spending: '지출',
  Bind_account:'계정 연동',
  Please_enter_the_transaction_password:'거래 비밀번호를 입력하세요',
  noSart:'아직 정회원에게 개방되지 않음',
  search:'검색',
  latest: '최신',
  hottest: '인기',
  p_e_search:'키워드를 입력하세요',
  Application_Notes:'신청 안내',
  issuer_desc:'NFT 발행자가 되려면 관련 법적 자격을 갖추어야 합니다. 자세한 내용은 공식 고객 서비스 또는 이메일로 문의하십시오',
  start:'시작',

  Make_NFT_fragments:'NFT 조각 만들기',
  NFTfile:'NFT 파일',
  Purchase_limit: '구매 제한',
  Purchase_limit_desc: '개인당 구매 제한',
  Currency: '통화',
  Buyback_price: '회수 가격',
  countDown: '카운트다운(시간)',
  Transaction_object_ID: '거래 객체 ID',
  Click_to_select_NFT_file: 'NFT 파일 선택',
  Click_to_select_Currency: '통화 선택',
  Click_to_select_countDown: '카운트다운 선택',
  p_e_price: '가격을 입력하세요',
  create_now:'지금 만들기',
  Release_record:'발행 기록'

}

const nft ={
  "collect": "컬렉션",
  "transfer": "NFT 전송",
  "pledgeRecord": "조각 질권 기록",
  "hangout": "리스트",
  "buytitle": "구매하고 싶어요",
  "buy": "구매",
  "sell": "판매",
  "selltitle": "판매하고 싶어요",
  "listcount": "리스트 수량",
  "owner": "소유자",
  "between": "범위",
  "remains": "남은",
  "hangoutlabel": ["현재 보유", "NFT 총 수량", "유형", "사전 판매 가격", "가격", "수량", "매회 구매 제한", "최대", "판매", "구매"],
  "hangplaceholder": ["리스트 가격을 입력하세요", "수량을 입력하세요", "", "최대 수량을 입력하세요"],
  holdnumber:'보유 수량',
  available_number:'사용 가능한 수',
}


const otc = {
  orderID: "주문 번호",
  amount: "금액",
  addtime: "시간",
  detailtitle: "거래 정보",
  logintips: [
    "귀하의 정보는 256비트 SGC로 보호됩니다",
    "암호화를 사용하여 안전하게 전송됩니다"
  ],
  detail: ["가격", "거래 수량", "지불 금액", "주문 번호", "시간", "고객 서비스에 문의하십시오"]
}

const onekey = {
  royalties: "로열티",
  hold: "보유 수량",
  all: "전체",
  selltips: [
    "NFT 로열티는 노력의 결실로 수익을 올릴 수 있는 간단하고 쉬운 방법입니다.",
    "NFT 로열티는 아티스트, 게임 개발자 및 콘텐츠 제작자가 이전에는 불가능했던 방식으로 이차 판매에서 수익을 올릴 수 있는 훌륭한 기회를 제공합니다.",
    "TNFT 로열티 거래 익스프레스를 통해 로열티를 사고 팔아 수익을 올릴 수 있습니다."
  ],
}

const order2 = {
  presale: "사전 판매",
  otc: "OTC 잔액",
  pay: "지불",
  c2c: "C2C 송금",
  waitconfirm: "심사 대기",
  pass: "통과",
  c2ctip: "C2C는 수동 송금이 필요합니다",
  status: ["", "지불 대기", "확인 대기", "완료", "실패"],
  confirm: ["주문 확인", "사전 판매 진행 중, 주문 완료 후 지정된 시간 내에 판매자에게 지불하십시오"],
  c2cpay: ["지원 통화", "주소", "지불 금액", "증명서 업로드"],
  position:['보유 수량'],
  otctip:'OTC-pay는 잔액을 사용하여 결제합니다. 확인해 주세요',
  missingfund:'거래 비밀번호가 없음',
  setfundtip:'거래 비밀번호를 설정하지 않았습니다. 먼저 거래 비밀번호를 설정하십시오.'

}

const home = {
  tabTitles: [
    '스포츠 베팅',
    '해시 게임',
    '전자 게임',
    '라이브 딜러'
  ],
  contentTitles: [
    ['라이브 베팅', '조기 시장'],
    ['행운의 해시', '해시 니우니우', '해시 뱅커', '행운의 숫자', '행운의 28'],
    ['낚시', '룰렛', '빅 스몰'],
    ['바카라', '블랙잭', '드래곤 타이거']
  ],
  contentSubtitles: [
    ['경기를 보면서 베팅', '미리 배당률을 알고 더 정확하게 베팅'],
    ['행운의 해시', '해시 니우니우', '해시 뱅커', '행운의 숫자', '행운의 28'],
    ['낚시', '룰렛', '빅 스몰'],
    ['바카라', '블랙잭', '드래곤 타이거']
  ],
  titles: [
    '인기 게임',
    '배당률',
    '지금 베팅'
  ],
  guides: [
    '초보자 가이드',
    '입금 및 출금',
    '자주 묻는 질문'
  ],
  guideDescs: [
    '게임 시작 방법 알아보기',
    '빠른 입금 및 출금',
    '일반적인 문제 해결'
  ],
  menu: [
    '홈',
    '공지사항'
  ],
  msg: '곧 출시 예정',
  newsTitle: '뉴스',
  moreNews: '더 보기 >'
}

export {
  tab,
  dialog,
  index,
  find,
  position,
  order,
  login,
  share,
  my,
  nft,
  otc,
  onekey,
  order2,
  home
}

