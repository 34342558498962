import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/login/reg.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '首页',
    }
  },
  {
    path: '/repwd',
    name: 'repwd',
    component: () => import('../views/login/repwd.vue'),
    meta: {
      title: '忘记密码',
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge/recharge.vue'),
    meta: {
      title: '充值',
    }
  },
  {
    path: '/recharge1',
    name: 'recharge1',
    component: () => import('../views/recharge/recharge1.vue'),
    meta: {
      title: '充值',
    }
  },
  {
    path: '/rechargeList',
    name: 'rechargeList',
    component: () => import('../views/recharge/list.vue'),
    meta: {
      title: '充值记录',
    }
  },
  {
    path: "/CoinRecharge/:coinType",
    name: "CoinRecharge",
    component: () => import("@/views/recharge/CoinRecharge.vue"),
    meta: {
      title: "充值",
      active: "user",
    },
    props: true,
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('../views/withdraw/withdraw.vue'),
    meta: {
      title: '提现',
    }
  },
  {
    path: "/article/:articleType/:articleId?",
    name: "article",
    component: () => import("@/views/article.vue"),
    meta: {
      title: "article",
      active: "article",
    },
    props: true,
  },
  {
    path: "/newsarticle/:articleType/:articleId?",
    name: "newsarticle",
    component: () => import("@/views/newsarticle.vue"),
    meta: {
      title: "newsarticle",
      active: "newsarticle",
    },
    props: true,
  },
  {
    path: '/withdrawList',
    name: 'withdrawList',
    component: () => import('../views/withdraw/list.vue'),
    meta: {
      title: '提现记录',
    }
  },
  {
    path: '/changePwd',
    name: 'changePwd',
    component: () => import('../views/changePwd.vue'),
    meta: {
      title: '修改密码',
    }
  },
  {
    path: '/simplePwd',
    name: 'simplePwd',
    component: () => import('../views/simplePwd.vue'),
    meta: {
      title: '简易密码登录',
    }
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component: () => import('../views/resetPwd.vue'),
    meta: {
      title: '密码恢复',
    }
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/question.vue'),
    meta: {
      title: '常见问题',
    }
  },
  {
    path: '/odds',
    name: 'odds',
    component: () => import('../views/odds.vue'),
    meta: {
      title: '赔率转换',
    }
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/system.vue'),
    meta: {
      title: '系统需求',
    }
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('../views/rule.vue'),
    meta: {
      title: '规则与条款',
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue'),
    meta: {
      title: '联系我们',
    }
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: () => import('../views/waiting.vue'),
    meta: {
      title: 'waiting',
    }
  },
  {
    path: '/tzLog',
    name: 'tzLog',
    component: () => import('../views/tzLog.vue'),
    meta: {
      title: '投注记录',
    }
  },
  {
    path: "/hash/:taskId/:gameId?",
    name: "hash",
    component: () => import("@/views/hash/index.vue"),
    meta: {
      title: "hashGame",
    },
    props: true,
  },
  {
    path: "/kaijiang",
    name: "kaijiang",
    component: () => import("@/views/hash/kaijiang.vue"),
    meta: {
      title: "kaijiang",
    },
    props: true,
  },
  {
    path: "/hashDetail/:taskId",
    name: "hashDetail",
    component: () => import("@/views/hash/detail.vue"),
    meta: {
      title: "hashDetail",
      active: "hashDetail",
    },
    props: true,
  },
  {
    path: "/football/:typeid?",
    name: "football",
    component: () => import("@/views/football/index.vue"),
    meta: {
      title: "football",
    },
    props: true,
  },
  {
    path: '/detail/:fid/:oddtype/:datatype',
    name: 'detail',
    component: () => import('../views/detail/detail.vue'),
    meta: {
      title: '详情',
    },
    props: true,
  },
  {
    path: '/liveDetail/:fid/:oddtype/:datatype',
    name: 'liveDetail',
    component: () => import('../views/detail/liveDetail.vue'),
    meta: {
      title: '滚球详情',
    },
    props: true,
  },
  {
    path: "/news/:newsState?",
    name: "news",
    component: () => import("@/views/News.vue"),
    meta: {
      title: "新闻",
      requiresAuth: true,
      active: "news",
    },
    props: true,
  },
  {
    path: '/tabbar',
    name: 'tabbar',
    redirect: '/index',
    component: () => import('../views/tabbar/tabbar.vue'),
    children: [
      {
        path: '/index/:typeid?',
        name: 'index',
        component: () => import('../views/index/index.vue'),
        meta: {
          title: '首页',
        },
        props: true,
      },
      {
        path: '/foot/:lid?',
        name: 'foot',
        component: () => import('../views/zuqiu/zuqiu.vue'),
        meta: {
          title: '足球',
        },
        props: true,
      },
      {
        path: '/mytask/:type?',
        name: 'mytask',
        component: () => import('../views/MyTask.vue'),
        meta: {
          title: '历史单据',
        },
        props: true,
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/order.vue'),
        meta: {
          title: '投注记录',
        }
      },
    ]
  },
  {
    path: '/:path(.*)',
    name: 'notFound',
    component: () => import('../views/notFound.vue'),
    meta: {
      title: '找不到页面'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
