const tab = {
  text: [
    'Home',
    'Football',
    'Betting Slip',
    'Betting Records'
  ]
}

const home = {
  tabTitles: [
    'Sports Betting',
    'Hash Games',
    'Electronic Games',
    'Live Dealer'
  ],
  contentTitles: [
    ['Live Betting', 'Early Markets'],
    ['Lucky Hash', 'Hash Niu Niu', 'Hash Banker', 'Lucky Number', 'Lucky 28'],
    ['Fishing', 'Roulette', 'Big Small'],
    ['Baccarat', 'Blackjack', 'Dragon Tiger']
  ],
  contentSubtitles: [
    ['Bet while watching the game', 'Know the odds in advance for more accurate betting'],
    ['Lucky Hash', 'Hash Niu Niu', 'Hash Banker', 'Lucky Number', 'Lucky 28'],
    ['Fishing', 'Roulette', 'Big Small'],
    ['Baccarat', 'Blackjack', 'Dragon Tiger']
  ],
  titles: [
    'Popular Games',
    'Odds',
    'Bet Now'
  ],
  guides: [
    'Beginner Guide',
    'Deposit & Withdrawal',
    'FAQ'
  ],
  guideDescs: [
    'Learn how to start playing',
    'Quick deposit and withdrawal',
    'Solve common problems'
  ],
  menu: [
    'Home',
    'Announcements'
  ],
  msg: 'Coming Soon',
  newsTitle: 'News',
  moreNews: 'More News >'
}

const dialog = [
  "Prompt",
  "Confirm",
  "Submitting...",
  "Copy Successful",
  "IOS system version too low to support",
  "Registering...",
  "Loading data...",
  "No more."
]

const index = {
  title: 'Early Markets',
  title2: 'Live Betting',
  zuqiu: 'Football',
  Alltime: 'All Time',
  timeAndPlay: 'Time/League'
}
const login = {
  accont: 'Login Account',
  phone: 'Phone Number',
  mail: 'Email',
  pwd: 'Password',
  placeholder1: 'Please enter phone number', //'
  placeholder_m: 'Please enter email', //'
  placeholder2: 'Please enter account', // 'Please enter your account',
  placeholder3: 'Please enter password', // 'Please enter a password',
  placeholder4: 'Please enter verification code', // 'Please enter a password',
  placeholder5: 'Please enter invitation code', // 'Please enter the recommendation code'
  forgotPwd: 'Forgot Password?',
  forgotPwd1: 'Forgot Password',
  Reset_password: 'Reset Password',
  login: 'Login',
  Dont_have_an_account_yet: "Don't have an account yet?",
  yklogin: 'Guest Login',
  zxkf: 'Online Customer Service',
  remember: 'Remember my account',
  Register_now: 'Register Now',
  Login_now: 'Go to Login',
  Register: 'Register',
  VerificationCode: 'Verification Code',
  ConfirmPassword: 'Confirm Password',
  getCode: 'Get Code',
  send: 'Sent',
  Referral_Code: 'Invitation Code',
  by: 'By clicking register, I agree to the',
  User_Agreement: 'User Agreement',
  submit: 'Confirm',

  read1: 'I have read and agree to',
  read2: 'the relevant terms',
  read3: 'and',
  read4: 'Privacy Policy',
}

const lang = {
  text: 'Language',
}

const recharge = {
  rechargeLog: 'Recharge Records',
  title: 'USDT Recharge Page',
  money: 'Amount',
  btn1: 'Scan the QR code to recharge and submit',
  recharge: 'Recharge',
  ERC20: 'ERC20',
  TRC20: 'TRC20',
  OMNI: 'OMNI',
  text: [
    'This address can only deposit USDT, deposits other than USDT are invalid and non-refundable',
    'TKQeybLDtotxGMxaEpROWgESjTtvGqH98L'
  ],
  copy: 'Copy',
  label: [
    "Category",
    "Address",
    "Recharge Amount",
    "Enter Amount",
    "Upload Voucher",
    "Copy Successful",
    "Pre-recharge Exchange"
  ],
  default: [
    "Wallet recharge",
    "Wallet pre-recharge",
    "Recharge method",
    "Recharge",
    "Confirm",
  ],
  coinRecharge: [
    'Virtual Currency Recharge', 'Currency Type', 'Recharge Address', "Recharge Address QR Code"
  ],
  balanceNotEnough:'Balance not enough',
  balanceTip:"go to recharge please!"
}

const withdraw = {
  withdrawLog: 'Withdrawal Records',
  withdraw: 'Withdraw',
  AccountBalance: 'Account Balance',
  FreezeBalance: 'Frozen Balance',
  WithdrawalAddress: 'Withdrawal Address',
  ChainName: 'Chain Name',
  WithdrawalAmount: 'Withdrawal Amount',
  TransactionPassword: 'Transaction Password',
  p1: 'Please enter withdrawal address',
  p2: 'Please enter withdrawal amount',
  p3: 'Please enter transaction password',
}

const my = {
  text: [
    'Account History',
    'Quota Modification Records',
    'Language',
    'Handicap',
    'Change Password',
    'Easy Password Login',
    'Password Recovery',
    'FAQs',
    'Odds Conversion',
    'System Requirements',
    'Rules and Terms',
    'Contact Us',
  ],
  text1: [
    'Activate'
  ],
  setting: 'Settings',
  help: 'Help',
  logout: 'Logout'
}


const zuqiu = {
  text: [
    'Time',
    'League',
    'League Filter',
    'Handicap',
    'Over/Under','Half Time','All'
  ],
  hands:{
    'mh':'Handicap HongKong',
   'wb':'Handicap HongKong2'
  },
  wanfa: [
    'Live Betting',
    'Early Markets',
  ],
  qiu: [
    'Football',
    'Basketball',
    'Esports Football',
    'Tennis',
    'Badminton',
  ],
  type: [
    'All Markets',
    'Handicap',
    'Goals',
    'Halves',
    'Interval',
  ],
  text1: [
    'Handicap',
    'Over/Under',
    'Straight Win',
  ],
  big: 'Over',
  small: 'Under',
  text2: [
    'Home',
    'Away',
    'Draw',
  ],
  zhudan: 'Betting Slip',
  xiazhu: 'Place Bet',
  month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  month_s: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',],
}

const order = {
  type: [
    'Transaction Status',
    'Data Analysis',
  ],
  allTy: 'All Sports',
  money1: 'Bet Amount',
  money2: 'Potential Win Amount',
  all: 'Total',
  removeAll: 'Are you sure you want to remove all bets?',
  table_title: [
    'Date',
    'Bet Amount',
    'Valid Amount',
    'Balance',
  ],
  tzlog:["League","Match","Final Score","Order Number","Bet Amount","Bet Choose","Bet Choose",
    "Bet Type","Odds","Prize","Status","Bet Time"],

}

const changePwd = {
  text: [
    'For security reasons, you must change your password every 30 days.',
    'Your password must follow these rules:',
    '1. At least two uppercase or lowercase letters and numbers (0-9), with a minimum length of 6 to 12 characters.',
    '2. Three different letters and numbers.',
    '3. No spaces allowed.',
  ],
  changePwd: 'Change Password',
  now_pwd: 'Current Password',
  new_pwd: 'New Password',
  sure_pwd: 'Confirm Password',
  btn1: 'Cancel',
  btn2: 'Submit',
}

const simplePwd = {
  simplePwd: 'Simple Password Login',
  text: [
    'Enter your simple password',
    'Set your simple password for quick login.',
    'Close',
    'Delete',
  ]
}

const resetPwd = {
  'resetPwd': 'Password Recovery',
  'text': [
    'Please enter your email address used for password recovery.',
    'Email Address',
    'Cancel',
    'Next Step',
  ]
}

const question = {
  title:'Help Center',
  question: 'Frequently Asked Questions',
  time: 'Last Updated Date:'
}

const odds = {
  odds: 'Odds Conversion',
  table_title1: [
    'Betting Type',
    'Bet Amount',
    'Odds',
    'Win',
    'Loss',
  ],
  title: 'Odds Conversion Table',
  table_title2: [
    'Hong Kong Odds',
    'Malaysian Odds',
    'Indonesian Odds',
  ]
}

const system = {
  system: 'System Requirements',
  text1: [
    'Mobile Operating System',
    'Operating System',
    'Requirement',
    'Android',
    'Android 7 or above',
    'iOS',
    'iOS 12 or above',
  ],
  text2: [
    'Mobile Browser',
    'Android Browser',
    'Requirement',
    'Chrome',
    'Version 87 or above',
    'QQ Browser',
    'Version 10 or above',
  ],
  text3: [
    'iOS Browser',
    'iOS Browser',
    'Requirement',
    'Safari',
    'Version 11 or above',
    'Chrome',
    'Version 84 or above',
  ],
  text4: [
    'Desktop Operating System',
    'Operating System',
    'Requirement',
    'Windows',
    'Windows 7 or 10',
    'Mac OS',
    'OS X10.10 or above',
  ],
  text5: [
    'Desktop Browser',
    'Windows Browser',
    'Requirement',
    'Chrome',
    'Version 85 or above',
    'Firefox',
    'Version 60 or above',
  ],
  text6: [
    'Unsupported Browsers and Operating Systems',
    'To provide the best user experience, we maintain a short list of supported browsers and operating systems. If bugs or issues arise in unsupported browsers or operating systems, we reserve the right not to fix them. If you choose to use unsupported browsers or operating systems, you may encounter performance or display issues.'
  ]
}

const type = {
  type: [
    'All',
    'In Progress',
    'Failed',
    'Successful',
  ]
}

const rule = {
  rule: 'Rules and Terms',
  text1: [
    'Terms of Use',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
  text2: [
    'Betting Acceptance Conditions',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
  text3: [
    'Disclaimer',
    '1. The use of services provided by this company is at the discretion of the customer, and the risks are borne by the customer. By participating in our services, the customer acknowledges that the services provided by this company are normal, reasonable, fair, and just.<br><br>2. Certain legal rules in the jurisdiction where you reside may not explicitly state whether online or offline gambling is allowed. Customers are responsible for ensuring that their gambling activities are legal in their jurisdiction at all times.'
  ],
}

const contactUs = {
  contactUs: 'Contact Us'
}

const tzLog = {
  type: [
    'Unsettled',
    'Settled',
    'Scheduled Bet',
  ]
}
const detail = {
  text: [
    'No live broadcasts','FullTime','HalfTime'
  ],
  type: [
    'All',
    'Popular',
    'Handicap & Over/Under',
    'Correct Score',
    'Goals',
    'Special',
  ]
}

const hash = {
  common: ["online service", "Cancel suspension","Confirm","Cancel","Please enter"],
  vanPull: ["No more data", "no data"],
  dialog: [
    "hint",
    "Sure",
    "is submitting...",
    "copy successfully",
    "Low version of IOS system does not support",
    "Registering...",
    "loading...",
  ],
  roomDescs:['Level 1','Level 2','Level 3','Level VIP'],
  rooms:["Starting investment","Enter room balance limit","Number of users in the room","Enter the room"],
  kaijiang:["Lottery draw",'View','the day before','the day after'],
  taskshow :[
    'Period','more draws','How to play','Betting ends','lottery record','Bet amount','Or enter the bet amount',
    'Account Balance','Place your bet now','stopped','Your account balance is insufficient, please recharge first',
    ['OK to buy','This bet','Whether to confirm the bet?','Cancel bet','Confirm bet','This bet was successful','check order','Return to betting'],
    ['The award period has expired,stop betting'],
    ["Current", "Results", "Next", "Remains"]
  ],
  task: {
    tabs: ["all","in progress", "expired", "completed","Waiting for merchant payment","Waiting for confirmation"],
    default: ["Order List",],
    msg: ["Submit review", "Submitted, please wait for review", "Submit failed, please resubmit"],
  },
  task3:["all","unpaid","Paid"],
  taskshow2:["congratulate","Winning","big and small dewdrops",'Single and double dewdrops','Total purchase',"Hour","minute","Second","enter the game","point",'Waiting for the lottery draw'],
  taskshow3:["The game requires a deposit of 10 times the bet amount,"],
  mytask:["Betting Records","Game Type","account","time","Period","Bet Amount","Lottery Results","Result","winning amount","order number","Lose","WIN","Insufficient balance","Price cannot be 0","Range error"],
  mytask2:["Not drawn yet", "Order details", "Need to deduct in advance"
    ,"Lottery information","Betting","Push","Partial win"],
  recharge2:['Please follow the information given below to complete the recharge','Amount cannot be 0','Screenshots must be uploaded'],
  tips:['Gradual opening in progress, please stay tuned.','敬请期待'],
  article:['system information'],
  news:[
    "News",
    ["FocusNews","BusinessNews","LuckyNews"],
  ],
}
export {
  detail,
  tzLog,
  dialog,
  withdraw,
  tab,
  index,
  login,
  lang,
  recharge,
  my,
  zuqiu,
  order,
  changePwd,
  simplePwd,
  resetPwd,
  question,
  odds,
  system,
  rule,
  contactUs,
  type,
  home,
  hash
}

